import React from "react";
import { Header } from "./Header";
import { Hero} from "./Hero";
import { Services } from "./Services";
import { Contact} from "./Contact";
import { Footer } from "./Footer";

const getYearsOfPractice = () => {
  const currentDate = new Date();
  const currentYear: number = currentDate.getFullYear();
  const yearStartedPracticing: number = 1980;
  const yearsOfPractice: number = currentYear - yearStartedPracticing;
  return yearsOfPractice;
}

const yearsOfPractice = getYearsOfPractice();

const Layout: React.FC = () => {

  return (
    <div className="gl-app">
      <Header/>

      <Hero yearsOfPractice={yearsOfPractice}/>

      <Services />

      <Contact yearsOfPractice={yearsOfPractice} />

      <Footer />
    </div>
  );
};

export { Layout };
