import React from 'react';
import { ServiceCard } from "./ServiceCard";

const servicesData = [
  {
    icon: "beach_access",
    heading: "Probate Services",
    description: [
      "We understand you have worked hard to attain all the things you have today.",
      "  At Grossi Law, we provide expert legal advice for ",
      <strong className="gl-strong">wills</strong>,
      ", ",
      <strong className="gl-strong">living wills</strong>,
      ", ",
      <strong className="gl-strong">powers of attorney</strong>,
      ", ",
      <strong className="gl-strong">trusts</strong>,
      ", ",
      <strong className="gl-strong">probate</strong>,
      " and ",
      <strong className="gl-strong">senior planning</strong>,
      ". Our goal is to make sure your assets stay with your beneficiaries, where they belong."
    ]
  },
  {
    icon: "medical_services",
    heading: "Personal Injury",
    description: [
      "When you have experienced a personal injury, George Grossi is the lawyer you can trust.",
      "  Whether it be a ",
      <strong className="gl-strong">car accident</strong>,
      ", ",
      <strong className="gl-strong">medical malpractice</strong>,
      ", ",
      <strong className="gl-strong">slip-and-fall</strong>,
      ", ",
      <strong className="gl-strong">workers compensation</strong>,
      ", ",
      "or ",
      <strong className="gl-strong">defective product injury</strong>,
      ", allow us to fight the settlement and get the compensation you deserve."
    ]
  },
  {
    icon: "house",
    heading: "Real Estate",
    description: [
      "For a hassle-free experience when it comes to all things real estate in Rhode Island, contact Attorney George Grossi today.",
      "  If you are seeking legal guidance regarding ",
      <strong className="gl-strong">closings</strong>,
      ", ",
      <strong className="gl-strong">purchasing</strong>,
      ", ",
      <strong className="gl-strong">selling</strong>,
      ", ",
      <strong className="gl-strong">short sales</strong>,
      ", ",
      <strong className="gl-strong">refinancing</strong>,
      " or ",
      <strong className="gl-strong">any other real estate matter</strong>,
      ", we are the team you can count on."
    ]
  }
]


const Services: React.FC = () => {
  return (
    <section className="gl-services">
      <div className="gl-services__content">
        <div className="gl-services__copy">
          <h2 className="gl-heading gl-heading--section gl-heading--services">Areas of Practice</h2>

          <p className="gl-services__description">
            We offer legal expertise in a variety of areas including wills, probate, senior planning, personal injury, medical malpractice, and real estate.
            At Grossi Law, we strive to provide affordable services, so your first consultation will always be free.  In situations where you cannot meet at
            our Warwick, RI office location, we can provide at-home and hospital visits to cater to your needs.  Our 24/7, 365, referral network grants you
            access to Rhode Island's best litigators at your convenience.
          </p>
        </div>

        <div className="gl-d-grid gl-services-cards">
          {servicesData.map((service, index) =>
            <ServiceCard icon={service.icon} heading={service.heading} key={index}>
              {service.description.map((description, index) =>
                <span key={index}>{description}</span>
              )}
            </ServiceCard>
          )}
        </div>
      </div>
    </section>
  )
}

export { Services };
