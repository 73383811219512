import React from "react";
import { Layout } from "../components/Layout";
import { Helmet } from "react-helmet";

const IndexPage = () => {
  return (
    <>
      <Helmet>
        <html lang="en" />
        <title>Grossi Law - The Law Offices of Attorney George Grossi</title>
        <meta charSet="utf-8" />
        <meta name="description" content="RI Lawyer | Rhode Island Will Lawyer | RI Power of Attorney Lawyer | Rhode Island Estate Lawyer | RI Probate Lawyer" />
        <meta name="keywords" content="ri lawyer, ri real estate lawyer, ri probate lawyer, ri will lawyer, ri malpractice lawyer, ri personal injury lawyer" />
        <link rel="canonical" href="https://grossilaw.com" />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <meta name="theme-color" content="#90713c" />
        <meta property="og:url" content="https://grossilaw.com"  />
        <meta property="og:title" content="Grossi Law - The Law Offices of Attorney George Grossi" />
        <meta property="og:description" content='RI Lawyer | Rhode Island Will Lawyer | RI Power of Attorney Lawyer | Rhode Island Estate Lawyer | RI Probate Lawyer' />
        <script async src="https://www.googletagmanager.com/gtag/js?id=UA-40342584-1"></script>
        <script>
          {`
            window.dataLayer = window.dataLayer || [];
            function gtag(){dataLayer.push(arguments);}
            gtag('js', new Date());
            gtag('config', 'UA-40342584-1');
          `}
        </script>
      </Helmet>

      <div className="gl-body">
        <Layout />
      </div>
    </>
  )
};

export default IndexPage;
