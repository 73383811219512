import React from "react";
import { initializeMap } from "../map";

interface ContactProps {
  yearsOfPractice: number;
}

const Contact: React.FC<ContactProps> = ({yearsOfPractice}) => {
  if (typeof document !== "undefined") {
    initializeMap();
  }

  return (
    <section className="gl-contact">
      <div className="gl-d-grid gl-contact__content">
        <div className="gl-contact__content-info">
          <div>
            <h2 className="gl-heading gl-heading--section gl-heading--contact">Contact Us</h2>
          </div>

          <p className="gl-contact__description">
            Today, Attorney Grossi has been practicing for over {yearsOfPractice} years, he has represented thousands of clients, providing quality, personalized services at affordable rates.
            Grossi Law is dedicated to the needs of our customers. Phone and email inquiries are always answered in a professional, prompt manner. Our office is equipped with voicemail which is continually monitored, even on evenings and weekends. Should an emergency happen, we at Grossi Law are there for our clients.
          </p>

          <div className="gl-d-grid gl-stats">
            <div className="gl-d-grid gl-stats__stat">
              <span className="gl-stats__stat-number">{yearsOfPractice}+</span>
              <span className="gl-stats__stat-text">years of experience</span>
            </div>
            <div className="gl-d-grid gl-stats__stat">
              <span className="gl-stats__stat-number">1000s</span>
              <span className="gl-stats__stat-text">of happy clients</span>
            </div>
            <div className="gl-d-grid gl-stats__stat">
              <span className="gl-stats__stat-number">FREE</span>
              <span className="gl-stats__stat-text">initial consultation</span>
            </div>
          </div>
        </div>

        <address className="gl-d-grid gl-address">
          <ul className="gl-list">
            <li className="gl-address__list-item"><h3 className="gl-address__heading">Get in Touch</h3></li>

            <li className="gl-address__list-item">
              <a className="gl-d-grid gl-address__link" href="https://www.google.com/maps/dir//Grossi+Law+-+George+J+Grossi+Attorney+at+Law,+70+Jefferson+Blvd+%23101,+Warwick,+RI+02888/@41.749683,-71.4372137,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e44b8c513cc89d:0x84255a275bf88eaa!2m2!1d-71.435025!2d41.749683" target="_blank" rel="noopener noreferrer">
                <span className="gl-d-grid gl-address__link-content">
                  <span className="gl-sr-only">Get directions:</span>
                  <span
                    className="material-icons-outlined gl-address__link-icon gl-address__link-icon--address"
                    aria-hidden="true"
                  >
                    location_on
                  </span>
                  <span className="gl-address__link-text">70 Jefferson Blvd, Suite 101, <br/> Warwick, RI, 02888-1057</span>
                </span>
              </a>
            </li>

            <li className="gl-address__list-item">
              <a className="gl-d-grid gl-address__link" href="tel:+14014861191" target="_blank" rel="noopener noreferrer">
                <span className="gl-d-grid gl-address__link-content">
                  <span className="gl-sr-only">Call our mobile phone:</span>
                  <span
                    className="material-icons-outlined gl-address__link-icon"
                    aria-hidden="true"
                  >
                    smartphone
                  </span>
                  <span className="gl-address__link-text">401.486.1191</span>
                </span>
              </a>
            </li>

            <li className="gl-address__list-item">
              <a className="gl-d-grid gl-address__link" href="mailto:grossilaw@msn.com" target="_blank" rel="noopener noreferrer">
                <span className="gl-d-grid gl-address__link-content">
                  <span className="gl-sr-only">Email us at:</span>
                  <span
                    className="material-icons-outlined gl-address__link-icon"
                    aria-hidden="true"
                  >
                    email
                  </span>
                  <span className="gl-address__link-text">grossilaw@msn.com</span>
                </span>
              </a>
            </li>
          </ul>
        </address>
      </div>

      <div className="gl-map"></div>
    </section>
  );
};

export { Contact };
