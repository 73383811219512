/// <reference types="@types/googlemaps" />

import markerImg from "./images/marker.svg";

const MAPS_API_KEY: string = "AIzaSyC73LDXZcnC-SN7Qhn8Z9zl2MMLPV2O_ZE";;
const GL_PLACE_ID: string = "ChIJncg8UYxL5IkRqo74WydaJYQ";
const mapStyles: google.maps.MapTypeStyle[] = [
  {
    "featureType": "landscape.man_made",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#f7f2e9"
      },
      {
        "weight": 4
      }
    ]
  },
  {
    "featureType": "landscape.natural",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#f7f2e9"
      }
    ]
  },
  {
    "featureType": "poi",
    "stylers": [
      {
        "visibility": "off"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#efe6d2"
      }
    ]
  },
  {
    "featureType": "road",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#c8a765"
      }
    ]
  },
  {
    "featureType": "transit",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#c8a765"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "geometry.fill",
    "stylers": [
      {
        "color": "#2f3b7a"
      }
    ]
  },
  {
    "featureType": "water",
    "elementType": "labels.text.fill",
    "stylers": [
      {
        "color": "#f7f2e9"
      }
    ]
  }
]

const initializeMap = (): void => {
  const mapsScript = document.createElement("script");
  mapsScript.src = `https://maps.googleapis.com/maps/api/js?key=${MAPS_API_KEY}&libraries=places`;
  document.head.appendChild(mapsScript);
  mapsScript.addEventListener("load", () => {
    const infowindow = new google.maps.InfoWindow();
    const myLatLng: google.maps.LatLngLiteral = {
      lat: 41.7497,
      lng: -71.435
    };
    const map: google.maps.Map = new google.maps.Map(document.querySelector(".gl-map") as HTMLElement, {
      zoom: 16,
      center: myLatLng,
      fullscreenControl: false,
      mapTypeControl: false,
      zoomControl: true,
      streetViewControl: false,
      styles: mapStyles
    });
    const service = new google.maps.places.PlacesService(map);
    service.getDetails({
        placeId: GL_PLACE_ID
    }, function(place, status) {
        if (status === google.maps.places.PlacesServiceStatus.OK) {
          const markerLabelName = place.name.replace("- George J Grossi Attorney at Law", "");
          const markerLabel = {
            className: 'gl-map-marker',
            color: '#151b37',
            fontWeight: 'bold',
            fontSize: '18px',
            text: markerLabelName,
          }
          const glMarker: google.maps.Icon = {
            url: markerImg,
            origin: new google.maps.Point(0, 0),
            labelOrigin: new google.maps.Point(14, 54)
          }
          const marker = new google.maps.Marker({
              map,
              position: (place.geometry as google.maps.places.PlaceGeometry).location,
              label: markerLabel,
              icon: glMarker
          });
          const markerContent =
            `
              <div class="gl-map-info-window">
                <div class="gl-map-info-window__heading">${place.name}</div>
                <div class="gl-map-info-window__address">${place.formatted_address}</div>
                <div class="gl-map-info-window__view">
                  <a href="${place.url}" class="gl-map-info-window__view-link" target="_blank">View on Google Maps</a>
                </div>
              </div>
            `;
          marker.addListener("click", () => {
            infowindow.setContent(markerContent);
            infowindow.open(map, marker);
          });
        }
    });
  })
}

export { initializeMap };
