import React from "react";

const Footer: React.FC = () => {

  const copyRightDate = new Date();
  const copyRightYear = copyRightDate.getFullYear();
  return (
    <footer className="gl-footer">
      <p className="gl-copyright">Site by <a href="mailto:adam.m.grossi@gmail.com" className="gl-copyright__creator" target="_blank" rel="noopener noreferrer"><strong className="gl-strong">Adam Grossi</strong></a>. Grossi Law © {copyRightYear}.</p>
    </footer>
  );
};

export { Footer };
