import React from "react";

const Header: React.FC = () => {
  return (
    <header className="gl-header">
      <div className="gl-d-grid gl-header__content">
        <h1 className="gl-header__heading">Grossi Law</h1>
        <ul className="gl-list gl-d-grid gl-header__contact-list">
          <li className="gl-header__contact-list-item">
            <a className="gl-d-flex gl-header__contact-cta" href="tel:+14014861191" target="_blank" rel="noopener noreferrer">
              <span className="gl-sr-only">Call our mobile phone:</span>
              <span
                className="material-icons-outlined gl-header__contact-cta-icon"
                aria-hidden="true"
              >
                smartphone
              </span>
              <span className="gl-header__contact-cta-text">
                401.486.1191
              </span>
            </a>
          </li>

          <li className="gl-header__contact-list-item">
            <a className="gl-d-flex gl-header__contact-cta" href="mailto:grossilaw@msn.com" target="_blank" rel="noopener noreferrer">
              <span className="gl-sr-only">Email us at:</span>
              <span
                className="material-icons-outlined gl-header__contact-cta-icon"
                aria-hidden="true"
              >
                email
              </span>
              <span className="gl-header__contact-cta-text">
                grossilaw@msn.com
              </span>
            </a>
          </li>

          <li className="gl-header__contact-list-item gl-header__contact-list-item--directions">
            <a className="gl-d-flex gl-header__contact-cta" href="https://www.google.com/maps/dir//Grossi+Law+-+George+J+Grossi+Attorney+at+Law,+70+Jefferson+Blvd+%23101,+Warwick,+RI+02888/@41.749683,-71.4372137,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e44b8c513cc89d:0x84255a275bf88eaa!2m2!1d-71.435025!2d41.749683" target="_blank" rel="noopener noreferrer">
              <span className="gl-sr-only">Get directions:</span>
              <span
                className="material-icons-outlined gl-header__contact-cta-icon"
                aria-hidden="true"
              >
                location_on
              </span>
              <span
                className="gl-header__contact-cta-text"
                data-text="grossilaw@msn.com"
              >
                70 Jefferson Blvd, <span className="gl-header__contact-cta-text-suite">Suite 101,</span> Warwick, RI, 02888
              </span>
            </a>
          </li>
        </ul>
      </div>
    </header>
  );
};

export { Header };
