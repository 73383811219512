import React from "react";

interface HeroProps {
  yearsOfPractice: number;
}

const Hero: React.FC<HeroProps> = (props) => {
  return (
    <main className="gl-hero">
      <div className="gl-d-grid gl-hero__content">
        <div className="gl-hero__content-right">
          <h2 className=" gl-heading gl-heading--hero">Rhode Island's lawyer for <br/><span className="gl-heading__em">personalized</span>, <span className="gl-heading__em">dedicated</span> services</h2>

          <p className="gl-hero__content-description">
            With over {props.yearsOfPractice} years of experience, Attorney George Grossi and Grossi Law offer affordable, quality legal services to clients in Rhode Island.
            Attorney Grossi is one of Rhode Island's premier lawyers providing expertise in Wills, Living Wills, Powers of Attorney, Trusts, Probate, and Elderly Planning among a multitude of others.
            At Grossi Law, your initial consultation will always be free.
          </p>

          <a href="tel:+14014861191" className="gl-cta gl-cta--hero gl-cta--animate" target="_blank" rel="noopener noreferrer">Call Now</a>
          <a href="https://www.google.com/maps/dir//Grossi+Law+-+George+J+Grossi+Attorney+at+Law,+70+Jefferson+Blvd+%23101,+Warwick,+RI+02888/@41.749683,-71.4372137,17z/data=!4m8!4m7!1m0!1m5!1m1!1s0x89e44b8c513cc89d:0x84255a275bf88eaa!2m2!1d-71.435025!2d41.749683" target="_blank" rel="noopener noreferrer" className="gl-cta gl-cta--ghost gl-cta--hero gl-cta--directions">Get Directions</a>
        </div>
      </div>
    </main>
  );
};

export { Hero };
