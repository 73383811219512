import React from 'react';

interface ServiceCardProps {
  icon: string,
  heading: string,
  children: (string | JSX.Element)[]
}

const ServiceCard: React.FC<ServiceCardProps> = ({ icon, heading, children }) => {
  return (
    <div className="gl-d-grid gl-service">
      <div className="gl-d-grid gl-service__header">
        <span className="gl-d-grid gl-service__icon-container">
          <span className="material-icons-outlined gl-service__icon" aria-hidden="true">{icon}</span>
        </span>

        <h3 className="gl-service__heading">{heading}</h3>
      </div>

      <div className="gl-service__body">
        <p className="gl-service__description">{children}</p>

        <div className="gl-service__footer">
          <a href="tel:+14014861191" className="gl-cta gl-cta--services">Free Consultation</a>
        </div>
      </div>
    </div>
  );
}

export { ServiceCard };
